<template>
  <el-card shadow="never">
    <el-form :model="formData" label-width="120px">
      <el-form-item label="协助类型：" :required="true">
        <el-select v-model="formData.helpType" placeholder="请选择协助类型" class="form-item" filterable>
          <el-option v-for="item in helpTypeList" :key="item.value" :label="item.name" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="协助人：" :required="true">
        <el-select v-model="formData.userId" placeholder="请选择协助人" class="form-item" filterable>
          <el-option v-for="item in userList" :key="item.id" :label="item.fullName+'【'+item.userName+'】'" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="单号：" :required="true">
        <el-input v-model.trim="formData.orderNo" maxlength="30" placeholder="请输入协助类型对应的单号" class="form-item"></el-input>
      </el-form-item>
      <el-form-item label="协助内容：" :required="true">
        <el-input type="textarea" v-model="formData.helpContent" maxlength="200" :autosize="true" style="width: 450px"></el-input>
      </el-form-item>
      <el-form-item label="图片：">
        <ImageUploadComponent url="upload/helpFile" ref="uploadComponent" :img-cnt="3" @imageChange="setImgIds"></ImageUploadComponent>
      </el-form-item>
      <el-form-item label="Excel文件：">
        <DocUploadComponent url="upload/helpFile" ref="excelUpload" accept=".xls,.xlsx" @docChange="setExcelIds" style="width: 450px"/>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" @click="submit">提交</el-button>
        <MessageComponent ref="commitMessage"/>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
import ImageUploadComponent from "@/components/ImageUploadComponent";
import DocUploadComponent from "@/components/DocUploadComponent";
import MessageComponent from "@/components/MessageComponent";

export default {
  name: "CreateHelp",
  components: {MessageComponent, DocUploadComponent, ImageUploadComponent},
  data() {
    return {
      formData: {
        helpType: '',
        userId: '',
        orderNo: '',
        helpContent: '',
        imageIds: []
      },
      userList: []
    }
  },
  created() {
    this.$axios.get('user/getAllUser').then(response => {
      let res = response.data;
      if (res.code !== 0) {
        return this.$message.error(res.message);
      }

      this.userList = res.data || [];
    }, error => {
      return this.$message.error(`获取人员列表失败，${error.message}`);
    })
  },
  computed: {
    helpTypeList() {
      return this.$store.getters.getDictList('helpType') || [];
    }
  },
  methods: {
    setImgIds(idList) {
      this.formData.imageIds = idList
    },
    setExcelIds(excelIds) {
      if (excelIds && excelIds.length > 0) {
        this.formData.fileId = excelIds[0];
      } else {
        this.formData.fileId = '';
      }
    },
    resetValues() {
      this.formData.helpType = ''
      this.formData.userId = ''
      this.formData.orderNo = ''
      this.formData.helpContent = ''
      this.formData.imageIds = []
      this.formData.fileId = ''
      this.$refs.excelUpload.onClear()
      this.$refs.uploadComponent.onClear()
    },
    submit() {
      this.$refs.commitMessage.clear();
      if (!this.formData.helpType) {
        return this.$refs.commitMessage.showError('请选择协助类型');
      }
      if (!this.formData.userId) {
        return this.$refs.commitMessage.showError('请选择协助人');
      }
      if (!this.formData.orderNo) {
        return this.$refs.commitMessage.showError('请输入协助类型对应的单号');
      }
      if (!this.formData.helpContent) {
        return this.$refs.commitMessage.showError('请选择协助内容');
      }

      this.$axios.post('help/createHelp', this.formData).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$refs.commitMessage.showError(res.message);
        }
        this.$refs.commitMessage.showSuccess('提交成功');
        this.resetValues()
      }, error => {
        this.loading = false;
        return this.$refs.commitMessage.showError(`提交失败，${error.message}`);
      })


    }
  }
}
</script>

<style scoped>
.form-item {
  width: 250px;
}
</style>